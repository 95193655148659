<template>
  <div class="wrap" v-if="!showTagMange">
    <p class="title">标签管理</p>
    <!-- 搜索 -->
    <div class="content">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="直播可选标签数">
          <a-input-number v-model:value="liveNum" :min="1"></a-input-number>
        </a-form-item>
        <a-form-item label="视频可选标签数">
          <a-input-number v-model:value="videoNum" :min="1"></a-input-number>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="saveTagConfig">保存</a-button>
        </a-form-item>
      </a-form>
      <!-- 按钮跳转 -->
      <div class="btns">
        <a-button type="dashed" @click="toggleTagManage('live')">直播标签管理</a-button>
        <a-button type="dashed" @click="toggleTagManage('video')">视频标签管理</a-button>
      </div>
    </div>
  </div>
  <video-live-manage v-else :tagType="tagType" @returnBack="returnBack"></video-live-manage>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, toRefs } from 'vue';
import api from '@/services';
import { message } from 'ant-design-vue';
import VideoLiveManage from './VideoLiveManage';

export default defineComponent({
  components: {
    VideoLiveManage
  },
  setup() {
    const labelCol = reactive({ span: 8 });
    const wrapperCol = reactive({ span: 16 });

    const showTagMange = ref(false);
    const tagType = ref('live');

    const formState = reactive({
      liveNum: 0,
      videoNum: 0
    });

    onMounted(() => {
      getTagConfig();
    });

    const getTagConfig = async () => {
      const { data, success } = await api.getTagConfig();
      if (success) {
        formState.liveNum = data.liveNum;
        formState.videoNum = data.videoNum;
      }
    };

    const saveTagConfig = async () => {
      let params = {
        ...formState
      };
      const { success } = await api.saveTagConfig(params);
      if (success) {
        message.success('保存成功！');
      }
    };

    const toggleTagManage = type => {
      tagType.value = type === 'live' ? 1 : 2;
      showTagMange.value = !showTagMange.value;
    };

    const returnBack = () => {
      showTagMange.value = false;
    };

    return {
      labelCol,
      wrapperCol,
      tagType,
      toggleTagManage,
      showTagMange,
      saveTagConfig,
      getTagConfig,
      returnBack,
      ...toRefs(formState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .content {
    display: flex;
    flex-direction: colomn;

    :deep(.ant-form) {
      width: 40%;
    }
    .btns {
      display: flex;
      width: 20%;
      flex-direction: column;
      :deep(.ant-btn) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
